/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import TimeField from '../components/react-simple-timefield/src/index.tsx';

@inject('t')
@observer
class CustomTimeField extends Component {
  render() {
    const { disabled, doOnChange, handleEnter, doOnBlur, attr, value, styles, input, doOnFocus } = this.props;

    return (
      <TimeField
        value={value || '--:--'}
        onChange={(event, value) => { doOnChange(attr, value != null ? value : '', event); }}
        colon=":"
        onBlur={(event) => {
          // We automatically turn inputs like 12 into 12:00
          const patchedValue = event.target.value.replace(':--', ':00');
          if (doOnBlur) {
            doOnBlur(attr, patchedValue, event);
          }
        }}
        style={{ color: 'black', ...styles }}
        // a test input prop for potentially intercepting a number like 8 (8-:--) and converting it to 08:--
        // onInputCapture={(e) => console.log('BEFORE CAPTURE', e.target.value)}
        input={input || <input type="text" className="mdc-text-field__input" />}
        onKeyPress={handleEnter}
        onClick={(event) => event.target.setSelectionRange(0, 0)}
        disabled={disabled}
        onFocus={() => doOnFocus && doOnFocus()}
      />
    );
  }
}

export default CustomTimeField;
